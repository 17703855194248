import React, { useEffect, useState } from 'react';
import { Box, Typography, Tab, Chip } from '@mui/material'
import { TabList, TabContext, TabPanel,} from '@mui/lab';
import { MuiMarkdown, getOverrides } from 'mui-markdown';
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import constants from '../constants';
import axios from 'axios'; 
import '@fontsource/roboto-mono/400.css';



export function ModulePage(props) {
    const userInfo = useSelector((state) => (state.user))
    const [module, setModule] = useState({})
    const [value, setValue] = useState('0');
    const [files, setFiles] = useState([])
    const [loading, setLoading] = useState(true)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const { id } = useParams();

    useEffect(() => {
        const fetchModule = async () => {
            const response = await axios.get(`${constants.SERVER_API}/module`,{
                params: {
                    id: id
                }
            })
            if (response.data) {
                console.log(response.data)
                setModule(response.data)
                fetchFiles(response.data); 
            }
            return response.data
        };

        const fetchFiles = async(module) => {
            console.log('Req', module, userInfo)
            const data = {
                owner: module.owner,
                repo: module.repo_name,
                path: module.link,
                uid: userInfo?.info.uid 
              }
            const response = await axios.post(`${constants.SERVER_API}/fetch`, data)
            console.log('fetch', response)
            setLoading(false)
            if (response.data) {
                setFiles(response.data)
            }
        }
        fetchModule();
    }, [userInfo]); 

    return (
        <Box>
        {
            loading ? (
                <Box>
                    Loading...
                </Box>
            ): 
            (<Box sx={{padding: '2em 2em'}}>
                <Typography variant="h7">
                    {module.repo_name}/{module.link}
                </Typography>
                <Box sx={{padding: '2em 0'}}>
                    <Typography variant="h5">
                            {module.name}
                    </Typography>
                    <Typography variant="body">
                            {module.description}
                    </Typography>
                </Box>
                <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="README" value="0" />
                                <Tab label="Module Info" value="1" />
                            </TabList>
                        </Box>
                        <TabPanel value="0">
                            <MuiMarkdown
                                overrides={{
                                    ...getOverrides({}), // This will keep the other default overrides.
                                    h1: {
                                        props: {
                                            style: { color: 'black', fontsize: '14' },
                                        },
                                    },
                                    h2: {
                                        props: {
                                            style: { color: 'black', fontsize: '12' },
                                        },
                                    },
                                }}
                            >
                                {`${files[0]}`}
                            </MuiMarkdown>
                        </TabPanel>
                        <TabPanel value="1">
                            <Box sx={{height: '50vh', overflowY: 'auto'}}>
                                <MuiMarkdown
                                
                                >
                                    {`${JSON.stringify(files[1], null, 2)}`}
                                </MuiMarkdown>
                            </Box>
                        </TabPanel>
                </TabContext>
            </Box>
            )
        }
        </Box>
    );

}
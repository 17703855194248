import React, { useEffect, useState } from 'react';
import constants from '../constants';
import Selector from '../components/Selector';
import { Box, Typography } from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux'



export function SettingsPage() {
    const userInfo = useSelector((state) => (state.user))
    const [modules, setModule] = useState([])
    const [communities, setCommunities] = useState([])
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);


    const fetchModules= async () => {
        try {
            await axios.get(`${constants.SERVER_API}/`, 
                { 
                    params: { user: userInfo.info.uid }
                }
            )
            .then(response => {
                if (response.data) {
                    setModule(response.data)
                }
            })
        } catch (err) {
            setError(err.message);
        }
    };

    const fetchCommunities = async() => {
        try {
            await axios.get(`${constants.SERVER_API}/communities`)
            .then(response => {
                if (response.data) {
                    setCommunities(response.data)
                }
                setLoading(false)
            })
        } catch (err) {
            setError(err.message);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchModules();
                await fetchCommunities();
            } catch (err) {
                setError(err.message);
                console.error('Error fetching data:', err);
            }
        };
        fetchData();
    }, [userInfo]); 

    return (
        <Box>
            {
                isLoading ? (
                    <Typography>Loading Modules</Typography>
                ) : (
                    <Selector modules={modules} communities={communities}/>
                )
            }
        </Box>
    );

}
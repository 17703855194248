import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';

export function HomePage() {
    return (
        <Box sx={{padding: '2em 0'}}>
            <Grid container justifyContent={'center'}>
                <Grid size={8} alignItems={'center'}>
                    <Typography sx={{textAlign: "center"}} variant="h2">
                        <strong>
                            Building Collective Knowledge for LLMs
                        </strong>
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent={'center'}>
                <Grid size={7} alignItems={'center'}>
                    <Typography sx={{textAlign: "center"}} variant="h6">
                        Knoll is a research prototype that supports using and creating dynamic, localized knowledge repositories for LLMs.
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );

}
import { configureStore } from '@reduxjs/toolkit'
import knowledgeReducer from './reducers/knowledgeReducer';
import userReducer from './reducers/userReducer';


export default configureStore({
  reducer: {
    knowledge: knowledgeReducer,
    user: userReducer
  }
})

import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';


export function LogoutPage() {

    return (
        <Box>
            <Grid container justifyContent={'flex-start'}>
                <Grid size={4}>
                    <Typography variant="h6">You have been successfully logged out.</Typography>
                </Grid>
            </Grid>
        </Box>
    );

}
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  info: {}, 
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUser: (state, action) => {
        console.log("Adding user to redux", action)
        if (action.payload) {
          state.info["uid"] = action.payload.uid
          state.info["name"] = action.payload.displayName
          console.log('redux', state.info["uid"])
        }
    },
    deleteUser: (state, action) => {
        console.log('Deleting user', action)
        delete state.info["uid"]
        delete state.info["name"]
    }
  },
})

export const { addUser, deleteUser } = userSlice.actions
export default userSlice.reducer
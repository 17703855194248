import React, { useEffect } from 'react';
import { Typography, Button, Box, Card, CardContent } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { LoginButton } from '../components/LoginButton';
import { useSelector } from 'react-redux'
import constants from '../constants';

export function DownloadedPage() {
    const userInfo = useSelector((state) => (state.user))
    
    useEffect(() => {
        if (userInfo?.info.uid) {
            try {
                console.log("Send message sign in")
                chrome.runtime.sendMessage(
                    constants.CHROME_ID,
                    { user: userInfo.info.uid, type: "sign_in" },
                    function(response) {
                    console.log(response)
                    }
                );
            } catch (error) {
                console.error(error);
            }
        }
    }, [userInfo]); 

    return (
        <Box sx={{padding: '2em 0', height: '80%'}}>
            {userInfo?.info.uid ? 
                (
                    <Grid container direction="column" justifyContent={'space-around'} alignItems={'center'} spacing={4}>
                        <Grid size={6}>
                            <Typography variant="h4">
                                <strong>
                                    Installation Complete! <br/>
                                    <br/>
                                </strong>
                            </Typography>
                            <Typography variant="h6">
                                Get started using the Community LM extension. 
                            </Typography>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container direction="column" justifyContent={'space-around'} alignItems={'center'} spacing={4}>
                        <Grid size={6}>
                            <Typography variant="h4">
                                <strong>
                                    Congrats!<br/>
                                    You have installed the Community LM browser extension.
                                    <br/>
                                </strong>
                            </Typography>
                            <Typography variant="h6">
                                Log in or sign up now to start adding your own knowledge when using ChatGPT. 
                            </Typography>
                        </Grid>
                        <Grid size={3}>
                            <LoginButton text={"Log in or sign up with Github"} link={'download'}/>
                        </Grid>
                    </Grid>
                )
            }
        </Box>
    );
}
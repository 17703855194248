console.log(process.env)
const CHROME_ID = process.env.REACT_APP_EXTENSION_ID;
const SERVER_API=process.env.REACT_APP_SERVER_API;
const TEMPLATE_LINK="https://github.com/dorazhao99/community-module-template/tree/main";
const OPEN_AI = "https://chatgpt.com/";

export default {
    CHROME_ID, 
    SERVER_API,
    OPEN_AI,
    TEMPLATE_LINK,
}
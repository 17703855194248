import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
    useNavigate
} from "react-router-dom";

export function NotFoundPage() {
    const navigate = useNavigate();
    return (
        <Box>
            <Grid sx={{height: "40vh"}} container justifyContent={'center'} alignItems={'center'} direction="column">
                <Grid size={5}>
                    <Typography variant="h2">
                        <strong>404: Page Not Found</strong>
                    </Typography>
                </Grid>
                <Grid sx={{margin: '2em 0'}} size={5}>
                    <Button sx={{width: "80%"}} onClick={()=>navigate('/')} variant="contained">
                        Take me home →
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );

}
import React, { useEffect, useState } from 'react';
import constants from '../constants';
import Grid from '@mui/material/Grid2';
import GitHubIcon from '@mui/icons-material/GitHub';
import GroupIcon from '@mui/icons-material/Group';
import LockIcon from '@mui/icons-material/Lock';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Typography, Paper, IconButton, Chip, Checkbox} from '@mui/material';
import { useNavigate } from "react-router-dom";
import './module.css'; 

export function Module(props) {

    const navigate = useNavigate();

    const handleClick = () => {
        console.log(props)
        window.open(props.link, '_blank');
    };

    const handleRedirect = () => {
        navigate(`/module/${props.id}`)
    }

    return (
        <Paper className="module">
            <Grid container spacing={1} alignItems="center" >
                <Grid size={1}>
                    <Checkbox id={props.id} variant="solid" checked={props.checked} onChange={props.onChange}/>
                </Grid>
                <Grid size={6} className="info" onClick={handleRedirect}>
                    <Typography variant="h6" align="left">
                        <strong>{props.title}</strong>
                        <IconButton type="button" aria-label="github">
                            <GitHubIcon onClick={handleClick}/>
                        </IconButton>
                        <Chip icon={<GroupIcon/>} label={Math.floor(2)}/> 
                             
                    </Typography>
                    <Typography align="left" variant="body1">
                        {props.description}
                    </Typography>
                </Grid>
                <Grid size={1}>
                    {props.access === 1 ? <div/> : <LockIcon/>}
                </Grid>
            </Grid>
        </Paper>
    );


}
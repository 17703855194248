import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material';

export function KnowledgePage() {
  const knowledge = useSelector((state) => (state.knowledge))

  const formatKnowledge = () => {
    const joinedStrings = Object.values(knowledge)
      .flat()
      .join('\n');
    console.log(joinedStrings)
    return joinedStrings
  }

  
  return (
    <Box sx={{padding: '1em'}}>
      <Typography>
        {Object.values(knowledge)}
        {console.log('State', knowledge)}
      </Typography>
    </Box>
  );
};


import React, { useEffect, useState } from 'react';
import { Typography, Button, Box, Card, CardContent } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { LoginButton } from '../components/LoginButton';
import { Link } from 'react-router-dom';

export function SignUpPage() {
    return (
        <Box sx={{padding: '2em 0', height: '80%'}}>
            <Grid sx={{height: '50%'}} container justifyContent={'center'} alignItems={'center'}>
                <Grid size={3}>
                    <Card>
                        <CardContent>
                            <Grid container direction="column" justifyContent={'center'}>
                                <Typography sx={{textAlign:"center"}} variant="h4">
                                    <strong>Join Now</strong>
                                </Typography>
                                <Typography sx={{textAlign:"center"}} variant="body">
                                    Already have an account? <Link to="/login">Log in.</Link>
                                </Typography>
                                <LoginButton text={"Continue with Github"}/>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}
import * as React from 'react';
import axios from 'axios';
import constants from '../constants.js';
import { useSelector, useDispatch } from 'react-redux'
import Grid from '@mui/material/Grid2';
import { updateKnowledge } from '../reducers/knowledgeReducer.js';
import { TabList, TabContext, TabPanel} from '@mui/lab';
import SearchIcon from '@mui/icons-material/Search';
import { Typography, Box, Tab, Button, InputBase, IconButton } from '@mui/material';
import { Module } from './Module.js';
import { CommunityOption } from './CommunityOption.js';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import githubApi from '../githubApi'

export default function Selector(props) {
  const [modules, setModules] = React.useState(props.modules)
  const [value, setValue] = React.useState('1');
  const [checked, setChecked] = React.useState({})
  const knowledge = useSelector((state) => (state.knowledge))
  const dispatch = useDispatch()

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setModules(props.modules)
  };

  const handleNew = () => {
    window.open(constants.TEMPLATE_LINK, '_blank');
  }

  const checkAll = (event, modules) => {
    let updateChecked = {...checked}
    modules.forEach((module, _) => {
      updateChecked[module] = event.target.checked
    })
    setChecked(updateChecked)
  }

  // const updateSelections = async() => {
  //   const requests = []
  //   Object.entries(checked).forEach((idx) => {
  //     if (idx[1]) {
  //       const mod = props.modules.find(item => item.id === idx[0]);
  //       const req = {
  //         owner: mod.owner,
  //         repo: mod.repo,
  //         path: mod.link,
  //         uid: localStorage.getItem("user")
  //       }
  //       requests.push(axios.get(`https://api.github.com/repos/${req.owner}/${req.repo}/contents/${req.path}`, {
  //         headers: {
  //             'Accept': 'application/vnd.github.raw+json',
  //             'Authorization': `Bearer ${TOKEN}`
  //         }
  //       }))
  //     }
  //   })

  //   let updatedKnowledge = {}
  //   axios.all(requests)
  //   .then(axios.spread((...responses) => {
  //       responses.forEach((response, _) => {
  //           if (response.data) {
  //             updatedKnowledge[response.data.name] = response.data.knowledge
  //           }
  //       });
  //       dispatch(updateKnowledge(updatedKnowledge))
  //   }))
  //   .catch(errors => {
  //       // Handle errors here
  //       console.error('Error making requests:', errors);
  //   });
  // }

  const handleChecked = (event) => {
    let updatedChecked = {...checked} 
    updatedChecked[event.target.id] = event.target.checked 
    setChecked(updatedChecked)
  }

  const filterItems = (event) => {
    const searchTerm = event.target.value
    const filteredModules = props.modules.filter(module =>
        module.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        module.description.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setModules(filteredModules)
  }

  return (
    <Box sx={{ width: '100%', typography: 'body1', padding: '1em 1em' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Communities" value="1" />
              <Tab label="Modules" value="2" />
            </TabList>
          </Box>
          <TabPanel value="2">
        <Box>
          <Grid container justifyContent={'space-between'}>
            <Grid size={6}>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Modules"
                inputProps={{'aria-label': 'Search Modules' }}
                onChange = {filterItems}
              />
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Grid>
            <Grid size={2}>
              <Button 
                size="small" 
                variant="contained"
                color="success" 
                startIcon={<LibraryBooksIcon/>}
                onClick={handleNew}
              >
                New Module
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ height: '60vh', overflowY: 'auto'}}>
          {
            modules.map((module, idx) => {
              return(
                <Module 
                  checked={checked[module.id] ? checked[module.id] : false}
                  onChange={handleChecked}
                  id={module.id}
                  title={module.name}
                  description={module.description}
                  link={module.gh_page}
                  access={module.access}
                />
              )
            })
          }
        </Box>
      </TabPanel>
      <TabPanel value="1">
        <Box sx={{ height: '55vh', overflowY: 'auto'}}>
          {
            props.communities.map((comm, _) => {
              return (
                <CommunityOption
                  checked = {checked}
                  name = {comm.name}
                  description = {comm.description}
                  modules = {comm.modules}
                  allModules = {Object.values(props.modules).map(module => module.id)}
                  onChange={handleChecked}
                  handleChangeAll={() => checkAll(event, comm.modules)}
                />
              )
            })
          }
        </Box>
      </TabPanel>
    </TabContext>
    {/* <Box>
      <Typography>
        Modules in Context
      </Typography>
      <Typography>
        {
          Object.keys(knowledge)
        }
      </Typography>
    </Box> */}
    {/* <Button onClick={updateSelections} variant="contained">
      Save
    </Button> */}
    </Box>
  );
}
/*global chrome*/
import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { NavBar } from "./components/NavBar";
import { ChatPage } from "./pages/ChatPage";
import { SettingsPage } from "./pages/SettingsPage";
import { HomePage } from "./pages/HomePage";
import { KnowledgePage } from "./pages/KnowledgePage";
import { RedirectPage } from "./pages/RedirectPage";
import { ModulePage } from "./pages/ModulePage";
import { UserPage } from './pages/UserPage';
import { LogoutPage } from './pages/LogoutPage';
import { LoginPage } from './pages/LoginPage';
import { NewModulePage } from './pages/NewModulePage';
import { DownloadedPage } from "./pages/DownloadedPage.js";
import { SignUpPage } from './pages/SignUpPage.js';
import { NotFoundPage } from './pages/NotFoundPage.js';
import { Box } from "@mui/material";
import { auth } from './firebase'
import { onAuthStateChanged } from "firebase/auth";
import { addUser, deleteUser } from './reducers/userReducer.js';
import constants from './constants'
import theme from "./theme";

export default function App() {
  const userInfo = useSelector((state) => (state.user))
  const dispatch = useDispatch()

  onAuthStateChanged(auth, (authUser) => {
    if (authUser) {
      console.log('Auth observer', authUser)
      const update = {
        uid: authUser.uid,
        displayName: authUser.displayName
      }
      dispatch(addUser(update))
    } else {
      console.log('Auth observer signed out')
      dispatch(deleteUser())
    }
  });
  // const [user, setUser] = useSelector((state) => (state.user.user))

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{height: "100vh", padding: "0 4em"}}>
        <Router>
          <NavBar user={userInfo}/>
          <Routes>
            {/* <Route path="chat" element={<ChatPage/>}/> */}
            {/* <Route path="redirect" element={<RedirectPage/>}/> */}
            <Route path="settings" element={<SettingsPage/>}/>
            <Route path="" element={<HomePage/>}/>
            <Route path="knowledge" element={<KnowledgePage/>}/>
            <Route path="module/:id" element={<ModulePage/>}/>
            <Route path="user/:id" element={<UserPage/>}/>
            <Route path="logout" element={<LogoutPage/>}/>
            <Route path="login" element={<LoginPage/>}/>
            <Route path="signup" element={<SignUpPage/>}/>
            <Route path="download" element={<DownloadedPage/>}/>
            <Route path="add" element={<NewModulePage/>}/>
            <Route path="*" element={<NotFoundPage />} /> {/* Catch-all route */}
          </Routes>
        </Router>
      </Box>
    </ThemeProvider>
  );
}

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  knowledge: {},
}

export const knowledgeSlice = createSlice({
  name: 'knowledge',
  initialState,
  reducers: {
    addKnowledge: (state, action) => {
        const module = action.payload
        state.knowledge[module.name] = module.knowledge
    },
    deleteKnowledge: (state, action) => {
        if (action.payload in state.knowledge) {
            delete state.knowledge[action.payload]
        } 
    },
    updateKnowledge: (state, action) => {
        const modules = action.payload
        state.knowledge = modules
    }
  },
})

// Action creators are generated for each case reducer function
export const { addKnowledge, deleteKnowledge, updateKnowledge } = knowledgeSlice.actions
export default knowledgeSlice.reducer
import { initializeApp, getApp } from 'firebase/app';
import { getAuth, initializeAuth, GithubAuthProvider } from 'firebase/auth';
import { initializeFirestore } from 'firebase/firestore';
import config from './config.js';

console.log(config)
function createFirebaseApp(config) {
    try {
      return getApp();
    } catch {
      return initializeApp(config);
    }
  }
  
export const firebase = createFirebaseApp(config.firebaseConfig);
export const auth = getAuth(firebase);
export const db = initializeFirestore(firebase, {
    experimentalForceLongPolling: true, // this line
    useFetchStreams: false, // and this line
})
const GithubProvider = new GithubAuthProvider();
GithubProvider.addScope('repo');
GithubProvider.addScope('read:org')
export const provider = GithubProvider; 
